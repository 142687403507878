// Dribbble Portfolio

init.push(function() {
  each(document.querySelectorAll('.dribbble'), function(holder) {
    var dribbbleUrl = 'https://api.dribbble.com/v2/user/shots?access_token='
    var accessToken = 'ecaa95c9730fa34b550ddc2d7f50a857408d55a8e642ad4741f1bacb499f33f6'    

    ajaxJSON(dribbbleUrl + accessToken, function(shots) {
      var output = ''
      each(shots, function(shot) {
        output += ([
          '<a class="dribbble__shot" href="'+ shot.html_url +'" target="_blank">',
            '<img src="'+ shot.images.two_x +'" />',
          '</a>',
        ].join(''));
      });
      holder.innerHTML = output
    })
  });
});
