// Social Share

init.push(function() {
  each(document.querySelectorAll('.share .share__menu a'), function(link) {
    link.onclick = function(e) {
      if (this.hasAttribute('share-new-window')) {
        e.preventDefault();
        var width = 550;
        var height = 450;
        var left = window.outerWidth / 2 - width / 2;
        var top = window.outerHeight / 2 - height / 2;
        window.open(this.href, '', 'width='+ width +', height='+ height +', left='+ left +', top='+ top +', menubar=0, toolbar=0, location=0, directories=0, resizable=yes, scrollbars=yes');
        return false;
      }
    }
  });
});