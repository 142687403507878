// Video Hover

init.push(function() {
  function isPlaying(video) {
    return video.currentTime > 0 && !video.paused && !video.ended  && video.readyState > 2;
  }

  each(document.querySelectorAll('[video-play-on-hover]'), function(el) {
    var video = (el.tagName.toLowercase == 'video') ? el : el.querySelector('video');

    el.onmouseover = function() {
      if (!isPlaying(video)) video.play();
    };

    el.onmouseout = function() {
      if (isPlaying(video)) video.pause();
    };
  });
});