// Dropdown

init.push(function() {
  each(document.querySelectorAll('.dropdown'), function(dropdown, i) {
    var trigger   = dropdown.querySelector('.dropdown__trigger');
    var input     = dropdown.querySelector('.dropdown__input');
    var menu      = dropdown.querySelector('.dropdown__menu');
    var menuItems = dropdown.querySelector('.dropdown__menu-items');
    var options   = dropdown.querySelectorAll('.dropdown__option');
    var canClick  = true;

    trigger.onclick = function(e) {
      e.preventDefault();
      if (!canClick) return; canClick = false;
      if (dropdown.classList.contains('is-active')) {
        animate(menu, [
          { height: menuItems.offsetHeight + 'px' },
          { height: 0 },
        ], duration, easing, function() {
          dropdown.classList.remove('is-active');
          menu.classList.remove('is-active');
          menu.style.height = '';
          canClick = true;
        });
      } else {
        animate(menu, [
          { height: 0 },
          { height: menuItems.offsetHeight + 'px' },
        ], duration, easing, function() {
          dropdown.classList.add('is-active');
          menu.classList.add('is-active');
          menu.style.height = '';
          canClick = true;
        });
      }
    }

    each(options, function(option, i) {
      option.onclick = function(e) {
        e.preventDefault();
        dropdown.style.width = dropdown.offsetWidth + 'px';
        var value = this.querySelector('span').getAttribute('value');
        var text = this.querySelector('span').innerHTML;
        animate(trigger, { opacity: 0 }, duration/2, function() {
          trigger.querySelector('span').innerHTML = text;
          animate(trigger, { opacity: 1 }, duration/2);
        });
        setTimeout(function() {
          each(options, function(option, i) {
            option.classList.remove('is-active');
          });
          option.classList.add('is-active');
          dropdown.style.width = '';
        }, duration);
        input.value = value;
        trigger.click();
        if (dropdown.hasAttribute('callback')) {
          try {
            window[dropdown.getAttribute('callback')](value);
          } catch(e) {
            console.log('Callback Function Error', e);
          }
        }
      }
    });
  });
});