(function () {
    var bar = document.querySelector('.bar.bar--default');
    var barLinks = bar.querySelectorAll('.bar__navigation a');
    var barNavigation = bar.querySelector('.modal.modal--style-navigation');
    var barTrigger = bar.querySelector('.bar__menu-trigger');

    var loader = document.querySelector('.loader');


    if (loader == null || loader.classList.contains('is-disabled')) {
        each(init, function (cb) {
            cb();
        });
        return false;
    }

    var loaderWiper = loader.querySelector('.loader__wiper');
    var loaderLogoLine = loader.querySelector('.loader__logo-line-1');
    var loaderDuration = parseFloat(getStyle(document.querySelector('.loader'), 'transitionDuration')) * 1100;

    Barba.Pjax.Dom.wrapperId = 'pjax-wrapper';
    Barba.Pjax.Dom.containerClass = 'pjax-container';

    document.addEventListener('DOMContentLoaded', function (e) {
        Barba.Pjax.originalPreventCheck = Barba.Pjax.preventCheck;

        Barba.Pjax.getTransition = function () {
            return Barba.BaseTransition.extend({
                start: function () {

                    loaderStart = Date.now();
                    loader.classList.add('is-showing');
                    Promise
                        .all([this.newContainerLoading, this.hiding()])
                        .then(this.showing.bind(this));

                },
                hiding: function () {

                    var barbaDeffered = Barba.Utils.deferred();
                    setTimeout(function () {
                        barbaDeffered.resolve();
                    }, minimumDelay(loaderStart, loaderDuration));
                    return barbaDeffered.promise;

                },
                showing: function () {

                    loaderStart = Date.now();
                    this.done();
                    barNavigation.classList.add('is-disabled');
                    barTrigger.classList.add('is-disabled');

                    var context = this.newContainer.getAttribute('context');
                    var action = this.newContainer.getAttribute('action');
                    var namespace = this.newContainer.getAttribute('namespace');

                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                    document.body.classList.remove('enable-scroll-to-top');
                    bar.classList.remove('is-active');
                    bar.classList.remove('is-category');

                    var barCategory = bar.querySelector('.bar.bar--style-category');
                    if (barCategory != null) barCategory.parentElement.removeChild(barCategory);

                    if (namespace == 'no-header') {
                        bar.classList.add('bar--no-header');
                    } else {
                        bar.classList.remove('bar--no-header');
                    }

                    each(barLinks, function (link) {
                        var linkContext = link.getAttribute('context');
                        var linkAction = link.getAttribute('action');
                        if (linkContext == context && (linkAction == '*' || linkAction == action)) {
                            link.classList.add('is-active');
                        } else {
                            link.classList.remove('is-active');
                        }
                    });

                    each(init, function (cb) {
                        cb();
                    });

                    setTimeout(function () {
                        loader.classList.remove('is-showing');
                        loader.classList.add('is-hiding');

                        setTimeout(function () {
                            loader.classList.remove('is-hiding');
                            barNavigation.classList.remove('is-disabled', 'is-active');
                            barTrigger.classList.remove('is-disabled', 'is-active');
                            pageShowed();
                        }, minimumDelay(loaderStart, loaderDuration));

                        pageStartShowing();
                    }, 0);

                }
            });
        };

        Barba.Pjax.start();

        setTimeout(function () {
            each(init, function (cb) {
                cb();
            });

            setTimeout(function () {
                loader.classList.add('is-loaded');
                loader.classList.remove('is-showing');
                loader.classList.add('is-hiding');

                setTimeout(function () {
                    loader.classList.remove('is-hiding');
                    pageShowed();
                }, minimumDelay(loaderStart, loaderDuration));

                pageStartShowing();
            }, 0);
        }, minimumDelay(loaderStart, loaderDuration * 1.5));

    }, false);

    function pageStartShowing() {
        // scrollReveal();
    }

    function pageShowed() {

    }

    // check whether we need to display the contact-us tab
    if ('#get-started' === location.hash) {
        document.querySelector('.modal.modal--style-get-started').classList.toggle('is-active');
    }
})();
