// Each
function each(a, cb, reverse) {
  if (typeof reverse !== 'undefined' && reverse == true) {
    for (var i = a.length - 1; i >= 0; i--) {
      cb(a[i], i);
    }
  } else {
    for (var i = 0, l = a.length; i < l; i++) {
      cb(a[i], i);
    }
  }
}

function eachObject(o, cb) {
  for (var key in o) {
    if (o.hasOwnProperty(key)) {
      cb(key, o[key]);
    }
  }
}

isArray = function(a) {
    return (!!a) && (a.constructor === Array);
}

isObject = function(a) {
    return (!!a) && (a.constructor === Object);
}