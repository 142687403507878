// Category Menu

init.push(function() {
  var barWrapper = document.querySelector('.bar.bar--style-submenu .bar__wrapper');
  if (barWrapper == null) return;

  windowResize('barResize', function() {
    var barNavigation = barWrapper.querySelector('.bar__navigation');
    var barNavigationHolder = barWrapper.querySelector('.bar__navigation > ul');
    var barNavigationItems = barWrapper.querySelectorAll('.bar__navigation-item');
    var barMore = barWrapper.querySelector('.bar__navigation-more');
    var barMoreHolder = barWrapper.querySelector('.bar__navigation-more > ul');
    var barMoreItems = barWrapper.querySelectorAll('.bar__navigation-more > ul > li');

    barMore.style.display = 'none';

    each(barMoreItems, function(el) {
      barNavigationHolder.insertBefore(el, barMore);
    });

    var barTooLarge = (barNavigation.offsetWidth > barWrapper.offsetWidth);

    barMore.style.display = barTooLarge ? 'block' : 'none';

    var appended = 0;

    each(barNavigationItems, function(el, i) {
      if ((barTooLarge || appended == 1) && i > 0) {
        var childNodes = barMoreHolder.querySelectorAll('li');
        if (childNodes.length == 0) {
          barMoreHolder.appendChild(el);
        } else {
          barMoreHolder.insertBefore(el, childNodes[0]);
        }
        barTooLarge = (barNavigation.offsetWidth > barWrapper.offsetWidth);
        appended++;
      }
    }, true);
  }, true);
});
