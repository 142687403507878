init.push(function() {
  var codes = document.querySelectorAll('code');

  if (codes.length > 0) {
    each(codes, function(code) {
      var pre = code.parentNode;
      var language = code.className.trim();
      if (language == 'javascript') language = 'js';
      code.className = 'language-' + language;
      pre.className = 'line-numbers';
    });

    Prism.highlightAll(false);
  }
});