// Scroll To Top

init.push(function() {
  var scrollToTop = {
    el: document.querySelector('.scroll-to-top'),
    active: false,
    boundary: 0,
  }

  if (scrollToTop.el == null) return;

  windowResize('scrollToTopResize', function(e) {
    scrollToTop.boundary = window.outerHeight / 2;
  }, true);

  windowScroll('scrollToTopScroll', function(e, scrollTop) {
    if (scrollTop > scrollToTop.boundary && !scrollToTop.active) {
      scrollToTop.active = true;
      document.body.classList.add('enable-scroll-to-top');
    } else if (scrollTop < scrollToTop.boundary && scrollToTop.active) {
      scrollToTop.active = false;
      document.body.classList.remove('enable-scroll-to-top');
    }
  }, true);
});