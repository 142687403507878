var easeIn = 'cubic-bezier(0.55, 0.085, 0.68, 0.53)';
var easeOut = 'cubic-bezier(0.25, 0.46, 0.45, 0.94)';
var easeInOut = 'cubic-bezier(0.455, 0.03, 0.515, 0.955)';

var duration = 400;
var easing = easeInOut;

var scroll, smoothScrollToOptions = {
  speed: 500,
  easing: 'easeInOutCubic',
  updateURL: false,
  emitEvents: false,
};

var init = [];