// Disable Body Scrollbar

var enableBodyScroll, disableBodyScroll;

(function() {
  var body = document.scrollingElement;
  var bar = document.querySelector('.bar.bar--default');
  var scrollToTop = document.querySelector('.scroll-to-top');
  var overflowDefault = window.getComputedStyle(body).overflowY;
  var disabled = false;

  disableBodyScroll = function() {
    var width = scrollbarWidth();
    if (width > 0 && !disabled) {
      disabled = true;
      body.style.overflowY = 'hidden';
      body.style.marginRight = width + 'px';
      bar.style.paddingRight = width + 'px';
      scrollToTop.style.paddingRight = width + 'px';
    }
  };

  enableBodyScroll = function() {
    if (disabled) {
      body.style.overflowY = overflowDefault;
      body.style.marginRight = '';
      bar.style.paddingRight = '';
      scrollToTop.style.paddingRight = '';
      disabled = false;
    }
  }

  function scrollbarWidth() {
    var inner = document.createElement('div');
    inner.style.width = '100%';
    inner.style.height = '200px';

    var outer = document.createElement('div');
    outer.style.position = 'absolute';
    outer.style.top = '0px';
    outer.style.left = '0px';
    outer.style.visibility = 'hidden';
    outer.style.width = '200px';
    outer.style.height = '150px';
    outer.style.overflow = 'hidden';
    outer.appendChild(inner);

    document.body.appendChild(outer);
    var w1 = inner.offsetWidth;
    outer.style.overflow = 'scroll';
    var w2 = inner.offsetWidth;
    if (w1 == w2) w2 = outer.clientWidth;
    document.body.removeChild(outer);

    return (w1 - w2);
  }
})();

window.modalScrollbar = function(active, el) {
  if (active) {
    each(el.querySelectorAll('.form__field.is-error'), function(field) {
      field.classList.remove('is-error');
    });
    disableBodyScroll();
  } else {
    var timeout = parseFloat(getStyle(el, 'transitionDuration')) * 1000;
    setTimeout(enableBodyScroll, timeout);
  }
}

