// Build Testimonial

function buildTestimonial(start, el) {

  var testimonial = document.querySelector('.modal__testimonial');

  var logo   = testimonial.querySelector('.modal__testimonial-logo');
  var avatar = testimonial.querySelector('.modal__testimonial-avatar');
  var name   = testimonial.querySelector('.modal__testimonial-name');
  var text   = testimonial.querySelector('.modal__testimonial-text');
  var buttonProject = testimonial.querySelector('.button--style-default');
  var buttonWebsite = testimonial.querySelector('.button--style-outline');

  logo.style.backgroundImage = 'url('+ el.getAttribute('testimonial-logo') +')';
  avatar.style.backgroundImage = 'url('+ el.getAttribute('testimonial-avatar') +')';

  name.innerHTML = el.getAttribute('testimonial-name') +', <br><span>'+
                   el.getAttribute('testimonial-company') +'</span>';
  text.innerHTML = el.getAttribute('testimonial-text');

  var urlProject = el.getAttribute('testimonial-project');
  var urlWebsite = el.getAttribute('testimonial-website');

  if (urlProject !== '') {
    buttonProject.setAttribute('href', urlProject);
    buttonProject.style.display = 'block';
  } else {
    buttonProject.style.display = 'none';
  }

  if (urlWebsite !== '') {
    buttonWebsite.setAttribute('href', urlWebsite);
    buttonWebsite.style.display = 'block';
  } else {
    buttonWebsite.style.display = 'none';
  }

}
