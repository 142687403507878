var windowResize, windowScroll;

(function() {
  var resizeFunctions = {};
  var scrollFunctions = {};

  windowResize = function(name, cb, call) {
    resizeFunctions[name] = (cb);
    if (typeof call !== 'undefined' && call === true) cb();
  }

  windowScroll = function(name, cb, call) {
    scrollFunctions[name] = (cb);
    var e, scrollTop = document.scrollingElement.scrollTop;
    if (typeof call !== 'undefined' && call === true) cb(e, scrollTop);
  }

  window.addEventListener('resize', function(e) {
    eachObject(resizeFunctions, function(name, cb) { cb(e); });
  }, passiveEvent);

  window.addEventListener('scroll', function(e) {
    var scrollTop = document.scrollingElement.scrollTop;
    eachObject(scrollFunctions, function(name, cb) { cb(e, scrollTop); });
  }, passiveEvent);
})();