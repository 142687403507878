// Alert

window.alertBox = function( text ) {
  var alert = document.querySelector('.alert');
  if (alert == null) return;

  var content = alert.querySelector('.alert__content');
  content.innerHTML = text;

  alert.classList.add('is-active');
};