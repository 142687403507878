// Data Toggle

init.push(function() {
  each(document.querySelectorAll('[data-toggle]'), function(el, i) {
    var targets = [];
    
    each(el.getAttribute('data-toggle').split(','), function(target) {
      var target = document.querySelector(target.trim());
      if (target == null) return;
      targets.push(target);
    });

    el.onclick = function(e) {
      if (el.hasAttribute('prevent-default')) e.preventDefault();

      el.classList.toggle('is-active');

      if (el.hasAttribute('data-callback')) {
        each(el.getAttribute('data-callback').split(','), function(callback) {
          try {
            window[callback](el.classList.contains('is-active'), el);
          } catch(e) {
            console.log('Callback Function Error', e);
          }
        });
      }

      each(targets, function(target, i) {
        target.classList.toggle('is-active');

        if (target.hasAttribute('data-callback')) {
          each(target.getAttribute('data-callback').split(','), function(callback) {
            try {
              window[callback](target.classList.contains('is-active'), target);
            } catch(e) {
              console.log('Callback Function Error', e);
            }
          });
        }
      });
    };
  });

  each(document.querySelectorAll('[data-toggle-parent]'), function(el, i) {
    var target = el.closest(el.getAttribute('data-toggle-parent'));

    el.onclick = function(e) {
      if (el.hasAttribute('prevent-default')) e.preventDefault();

      el.classList.toggle('is-active');

      if (el.hasAttribute('data-callback')) {
        each(el.getAttribute('data-callback').split(','), function(callback) {
          try {
            window[callback](el.classList.contains('is-active'), el);
          } catch(e) {
            console.log('Callback Function Error', e);
          }
        });
      }

      if (target == null) return;
      target.classList.toggle('is-active');

      if (target.hasAttribute('data-callback')) {
        each(target.getAttribute('data-callback').split(','), function(callback) {
          try {
            window[callback](target.classList.contains('is-active'), target);
          } catch(e) {
            console.log('Callback Function Error', e);
          }
        });
      }
    };
  });
});