init.push(function () {

  var filterable = document.querySelector('.filterable');
  if (filterable == null) return;
  
  var holder = filterable.querySelector('.filterable__holder');
  var wrapper = filterable.querySelector('.filterable__wrapper');
  var scrollTo = filterable.querySelector('.filterable__scroll-to');
  var loader = filterable.querySelector('.filterable__loader');

  var searchField = document.querySelector('.bar__search-field');
  var searchClear = document.querySelector('.bar__search-clear');

  if (holder == null || wrapper == null) return;

  var filterActive = false;
  var filters, pagination

  initFilterLinks();

  function initFilterLinks() {
    filters = document.querySelectorAll('.filterable--filter');

    each(filters, function (filter, i) {
      filter.onclick = function(e) {
        e.preventDefault();
        e.stopPropagation();
        filterClick(this);
      };
    });
  }

  function filterClick(filter) {
    var href = filter.getAttribute('href');

    each(filters, function (el, i) {
      el.classList.remove('is-active');
    });

    var menuLink = document.querySelector('.bar__navigation-item a[href="'+ href +'"]');
    if (menuLink !== null) {
      menuLink.classList.add('is-active');
    } else {
      document.querySelector('.filterable--all-categories').classList.add('is-active');
    }

    if (searchField !== null && searchField.classList.contains('is-active')) {
      searchClear.classList.remove('is-active');
      searchField.classList.remove('is-active');
    }

    filterItems(href);
  }

  function filterItems(action, callback) {
    if (filterActive) return false;
    filterActive = true;
    
    var startTime = Date.now();
    
    callback = callback || function () {};
    
    animate(wrapper, {opacity: 0}, duration / 2, easing);
    css(holder, {height: (wrapper.offsetHeight + ((pagination != null) ? pagination.offsetHeight : 0)) + 'px'});
    loader.classList.add('is-active');
    
    if (scrollTo != null) scroll.animateScroll(scrollTo, scrollTo, smoothScrollToOptions);

    ajax({
      action: action,
      csrf: true,
      success: function (data) {
        if (data !== false) {
          window.history.pushState('', '', action);

          setTimeout(function () {
            var div = document.createElement('div');
            div.innerHTML = data;
            
            holder.innerHTML = div.querySelector('.filterable__holder').innerHTML;
            wrapper = holder.querySelector('.filterable__wrapper');
            if (wrapper == null) wrapper = holder.querySelector('.empty');
            pagination = holder.querySelector('.filterable__pagination');
            
            animate(holder, {height: (wrapper.offsetHeight + ((pagination != null) ? pagination.offsetHeight : 0)) + 'px'}, duration, easing);
            
            var items = wrapper.querySelectorAll('.filterable__item');
            each(items, function (item) {
              css(item, {opacity: 0, transform: 'scale(0.9)'});
            });
            css(wrapper, {opacity: 1});
            
            loader.classList.remove('is-active');
            
            each(items, function (item, i) {
              setTimeout(function () {
                animate(item, {opacity: 1, transform: 'none'}, duration, easing);
              }, i * 50);
            });

            setTimeout(function () {
              initFilterLinks();
              filterActive = false;
              css(holder, {height: ''});
              if (typeof initLoadMore === 'function') initLoadMore();
            }, duration + items.length * 50);
            
            callback();
            
          }, minimumDelay(startTime, duration / 2));
        }
      }
    });
  }

  if (searchField != null & searchClear != null) {
    searchField.form.onsubmit = function (e) {
      e.preventDefault();
      
      if (searchField.value === '') {
        clearSearch();
        return false;
      }
      
      filterItems(searchField.form.getAttribute('action') + '/' + searchField.value);
      
      return false;
    };
    
    window.categorySearchBar = function (active, el) {
      if (!active) {
        if (searchField.value !== '') clearSearch();
        searchField.value = '';
      } else {
        searchField.focus();
      }
    }
  }

  function clearSearch() {
    filterClick(document.querySelector('.bar__navigation-item .filterable--filter'));
  }

});