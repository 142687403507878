// Ajax Form

(function() {
  'use strict';

  init.push(function() {
    each(document.querySelectorAll('[ajax-form]'), function(form) {
      form.setAttribute('ajax-status', 'ready');
      form.querySelector('input[name="_csrf"]').setAttribute('ajax-input', 'required');
      form.fileInputs = [];

      if (form.querySelectorAll('[ajax-file]').length > 0) {
        var files = form.querySelectorAll('[ajax-file]');
        each(files, function(input) {
          var data = {
            container: input,
            name: input.querySelector('[ajax-file-input]').getAttribute('name'),
            list: input.querySelector('[ajax-file-list]'),
            listItemTemplate: input.querySelector('[ajax-file-template]'),
            dropZone: input.querySelector('[ajax-file-drop-zone]'),
            input: input.querySelector('[ajax-file-input]'),
            supportedFiles: input.getAttribute('supported').split(','),
            maxFiles: parseInt(input.getAttribute('max-files')),
            maxSize: parseInt(input.getAttribute('max-size')),
            files: [],
          };
          initListeners(form, input, data);
          form.fileInputs.push(data);
        });
      }

      form.onsubmit = submitForm;
    });
  });

  function submitForm(e) {
    var form = this;
    e.preventDefault();
    if (form.getAttribute('ajax-status') != 'ready') return;

    var submit = form.querySelector('[ajax-submit]');
    var inputs = form.querySelectorAll('[ajax-input]');
    var files  = form.fileInputs;
    var method = form.getAttribute('method') || 'post';
    var action = form.getAttribute('action');
    var error = (!submit || !inputs[0] || !action);

    each(inputs, function(input) {
      input.onfocus = focusHandler;
      if (!validateInput(input, input.value)) error = true;
    });

    each(files, function(data) {
      if (!validateFiles(data)) error = true;
    });

    if (!error) {
      var data = new FormData();

      each(inputs, function(input) {
        if (
          input.hasAttribute('type') &&
          input.getAttribute('type').toLowerCase() == 'checkbox'
        ) {
          input.value = input.checked ? 1 : 0;
        }
        data.append(input.getAttribute('name'), input.value);
      });

      form.setAttribute('ajax-status', 'busy');
      submit.classList.add('is-busy');

      each(files, function(fileGroup) {
        each(fileGroup.files, function(file) {
          data.append( fileGroup.name, file );
        });
      });

      ajax({
        method: method,
        action: action,
        data: data,
        success: function(result) {
          form.setAttribute('ajax-status', 'disabled');
          submit.classList.remove('is-busy');
          submit.classList.add('is-disabled');
          if (typeof window[form.getAttribute('ajax-form')] == 'function') {
            window[form.getAttribute('ajax-form')](String(result));
          }
        }
      });
    }
  }

  function focusHandler() {
    var formField = this.closest('.form__field');
    if (formField != null) formField.classList.remove('is-error');
  }

  function validateFiles(data) {
    var valid = data.files.length >= 1;
    var formField = data.input.closest('.form__field');
    if (formField != null) formField.classList[(valid) ? 'remove' : 'add']('is-error');
    return valid;
  }

  function validateInput(input, value) {
    var valid = true;
    input.getAttribute('ajax-input').split(' ').forEach(function(type) {
      if (typeof validate[type] !== 'undefined')
        if (!validate[type](value, input))
          valid = false;
    });
    var formField = input.closest('.form__field');
    if (formField != null) formField.classList[(valid) ? 'remove' : 'add']('is-error');
    return valid;
  }

  var validate = {
    blank: function(value) {
      return value === '';
    },
    required: function(value) {
      return value !== '';
    },
    email: function(value) {
      return /\S+@\S+\.\S+/.test(value);
    },
    phone: function(value) {
      value = value.replace(/\+|\-|\ |\/|\(|\)/g,'');
      return !isNaN(parseFloat(value)) && isFinite(value);
    },
    recaptcha: function(value, input) {
      var container = input.closest('.form__container');
      var recaptcha = container.querySelector('.g-recaptcha-response');
      input.value = recaptcha.value;
      return input.value !== '';
    }
  };

  window.resetForm = function(form) {
    form.setAttribute('ajax-status', 'ready');
    each(form.querySelectorAll('.form__field'), function(field) {
      field.classList.remove('is-error');
    });
    each(form.querySelectorAll('.field'), function(field) {
      field.classList.remove('is-error');
      field.value = '';
    });
    each(form.querySelectorAll('.checkbox__helper'), function(field) {
      field.checked = true;
    });
    each(form.querySelectorAll('[ajax-file-input]'), function(field) {
      field.value = '';
    });
    each(form.querySelectorAll('.g-recaptcha-response'), function(field) {
      field.value = '';
    });
    if (typeof grecaptcha != 'undefined') grecaptcha.reset();
    each(form.querySelectorAll('[ajax-file]'), function(list) {
      list.setAttribute('files-uploaded', 0);
    });
    each(form.querySelectorAll('[ajax-file-list] > *:not([ajax-file-template]'), function(field) {
      field.parentNode.removeChild(field);
    });
    if (form.fileInputs) {
      each(form.fileInputs, function(fileInput) {
        fileInput.files = [];
      });
    }
  };

  function initListeners( form, input, data ) {
    each(['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'], function(eventName) {
      data.dropZone.addEventListener(eventName, function(e) {
        e.preventDefault();
        e.stopPropagation();
      });
    });

    function eventAddClass(e) { data.dropZone.classList.add('is-dragover'); }
    data.dropZone.addEventListener('dragover', eventAddClass);
    data.dropZone.addEventListener('dragenter', eventAddClass);

    function eventRemoveClass(e) { data.dropZone.classList.remove('is-dragover'); }
    data.dropZone.addEventListener('dragleave', eventRemoveClass);
    data.dropZone.addEventListener('dragend', eventRemoveClass);
    data.dropZone.addEventListener('drop', eventRemoveClass);
    data.dropZone.addEventListener('click', eventRemoveClass);

    data.input.addEventListener('change', function(e) { addFiles( data.input.files, data ); });
    data.dropZone.addEventListener('drop', function(e) { addFiles( e.dataTransfer.files, data ); });
  }

  function addFileItem(file, data) {
    var listItem = data.listItemTemplate.cloneNode(true);
    listItem.removeAttribute('ajax-file-template');

    listItem.querySelector('[ajax-file-name]').innerHTML = file.name;
    listItem.querySelector('[ajax-file-size]').innerHTML = formatBytes(file.size);
    data.list.appendChild(listItem);

    listItem.querySelector('[ajax-file-remove]').onclick = function(e) {
      each(data.files, function( file, i ) {
        if (file.name == listItem.querySelector('[ajax-file-name]').innerHTML) {
          var fileBufferIndex = data.files.indexOf(file);
          data.files.splice(fileBufferIndex, 1);
        }
      });

      listItem.parentNode.removeChild(listItem);
      data.container.setAttribute('files-uploaded', data.files.length);
    };
  }

  function addFiles(files, data) {
    each(files, function( file, i ) {
      if (i >= data.maxFiles) return;

      var sameFile = false;
      each(data.files, function( old, i ) {
        if (file.name == old.name && file.size == old.size && file.lastModified == old.lastModified) {
          sameFile = true;
        }
      });
      if (sameFile) return;

      if (data.supportedFiles[0] != '*') {
        var extension = file.name.split('.').pop();
        if (data.supportedFiles.indexOf(extension) < 0) {
          alertBox(data.container.getAttribute('alert-unsupported'));
          return;
        }
      }

      if (file.size > data.maxSize) {
        alertBox(data.container.getAttribute('alert-too-large'));
        return;
      }

      if (data.maxFiles == 1) {
        data.files = [];
        var listItem = data.container.querySelector('[ajax-file-list] > *:not([ajax-file-template])');
        if (listItem != null) {
          listItem.querySelector('[ajax-file-name]').innerHTML = file.name;
          listItem.querySelector('[ajax-file-size]').innerHTML = formatBytes(file.size);
        } else {
          addFileItem(file, data);  
        }
      } else if (data.files.length + 1 > data.maxFiles) {
        alertBox(data.container.getAttribute('alert-limit'));
        return;
      } else {
        addFileItem(file, data);
      }

      data.files.push(file);
    });

    var formField = data.input.closest('.form__field');
    if (formField != null) formField.classList.remove('is-error');

    data.container.setAttribute('files-uploaded', data.files.length);
    data.input.value = '';
  }

  function formatBytes(bytes) {
    if (bytes == 0) return '0 B';
    var sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    var i = Math.floor(Math.log(bytes) / Math.log(1024));
    return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i];
  }
})();

window.ajaxFormContact = function(data) {
  contentSwitch('get-started', (data == 'success') ? 'thank-you' : 'error', function() {
    resetForm(document.querySelector('.modal--style-get-started form'));
  });
};

window.ajaxFormBecomeAmigo = function(data) {
  contentSwitch('become-amigo', (data == 'success') ? 'thank-you' : 'error', function() {
    resetForm(document.querySelector('.modal--style-become-amigo form'));
  });
};

window.ajaxFormSubscribe = function (data) {
  jsonData = JSON.parse(data);
  contentSwitch('subscribe', (jsonData.status === 1) ? 'thank-you' : 'error', function() {
    messageSelector = jsonData.status === 1 ? '#success-message' : '#error-message';
    document.querySelector('.modal--style-subscribe ' + messageSelector).innerHTML = jsonData.message;
    resetForm(document.querySelector('.modal--style-subscribe form'));
  });
};