// Carousel

init.push(function() {
  each(document.querySelectorAll('.carousel'), function(carousel, i) {
    var wrapper = carousel.querySelector('.carousel__wrapper');
    var interval = parseInt(carousel.getAttribute('carousel-interval'));
    var id = carousel.getAttribute('carousel');
    var sliderActive = false;
    var sliderChanging = false;
    var timeout;

    var ease = 'cubic-bezier(0.455, 0.03, 0.515, 0.955)';
    var easeIn = 'cubic-bezier(0.55, 0.085, 0.68, 0.53)';
    var easeOut = 'cubic-bezier(0.25, 0.46, 0.45, 0.94)';
    var duration = 700;

    var autoHeight = carousel.hasAttribute('carousel-auto-height');
    var item = carousel.querySelectorAll('.carousel__slide');
    var count = item.length;
    var current = 0;

    each(item, function(item, i) {
      if (i == 0) item.classList.add('is-active');
    });

    var dots = document.querySelector('.carousel__dots[carousel="'+ id +'"]');
    if (dots != null) {
      removeChild(dots);
      for (var i = 0; i < count; i++) {
        (function() {
          var dot = (dots.tagName.toLowerCase() == 'ul')
            ? document.createElement('li')
            : document.createElement('span');
          if (i == 0) dot.classList.add('is-active')
          dots.appendChild(dot);
        })();
      }
      dots = dots.querySelectorAll('*');
      each(dots, function(dot, i) {
        dot.onclick = function(e) {
          e.preventDefault();
          if (sliderChanging) return;
          sliderActive = false;
          changeSlide(i);
        }
      });
    } else {
      dots = false;
    }

    var arrows = false;

    if (carousel.hasAttribute('auto-start')) {
      timeout = setTimeout(changeSlide, interval);
    }

    function changeSlide(newIndex) {
      if (sliderChanging) return;
      sliderChanging = true;
      clearTimeout(timeout);

      var oldIndex = current;
      if (typeof newIndex !== 'undefined') {
        if (oldIndex == newIndex) {
          sliderChanging = false;
          return false;
        }
        current = newIndex;
      } else {
        current = (current++ < count - 1) ? current : 0;
      }

      var slideRight = current > oldIndex;
      var old = item[oldIndex];
      var next = item[current];

      if (dots !== false) {
        dots[current].classList.add('is-active');
        dots[oldIndex].classList.remove('is-active');
      }

      if (arrows !== false) {
        arrows.current.innerHTML = (current + 1);
        if (current == 0) {
          arrows.prev.classList.add('is-disabled');
        } else {
          arrows.prev.classList.remove('is-disabled');
        }
        if (current == count-1) {
          arrows.next.classList.add('is-disabled');
        } else {
          arrows.next.classList.remove('is-disabled');
        }
      }

      next.classList.add('is-active');

      if (autoHeight) {
        animate(slider, [
          { height: old.offsetHeight + 'px', transform: 'translateZ(0)' },
          { height: next.offsetHeight + 'px', transform: 'translateZ(0)' }
        ], duration, easeInOut);
      }
      animate(old, [
        { position: 'absolute', top: 0, left: 0, transform: 'translateX(0) translateZ(0)' },
        { transform: 'translateX('+ (slideRight ? '-100%' : '100%') +') translateZ(0)', }
      ], duration, easeInOut);
      animate(next, [
        { position: 'absolute', top: 0, left: 0, transform: 'translateX('+ (slideRight ? '100%' : '-100%') +') translateZ(0)' },
        { transform: 'translateZ(0)' }
      ], duration, easeInOut, function() {
        old.classList.remove('is-active');

        if (autoHeight) { css(slider, { transition: '', height: '' }); }
        css(old, { position: '', top: '', left: '', transform: '', transition: '' });
        css(next, { position: '', top: '', left: '', transform: '', transition: '' });

        sliderChanging = false;
        if (sliderActive) timeout = setTimeout(changeSlide, interval);
      });
    }
  });
});