// Ajax

function ajax(options) {
  options = (typeof options === 'undefined') ? {} : options;

  var method   = options.method || 'post';
  var action   = options.action || '';
  var data     = options.data || new FormData();
  var success  = options.success || function() {};

  if (options.csrf === true) {
    data.append(
      document.head.querySelector('meta[name="csrf-param"]').getAttribute('content'),
      document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
    );
  }

  var xhr = new XMLHttpRequest();
  xhr.onload = function() {
    success((xhr.status === 200) ? xhr.responseText : false);
  };
  // xhr.upload.addEventListener('progress', function(a) { console.log(a); }, false);
  xhr.open(method, action);
  xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
  xhr.send(data);
}

// Ajax JSON

function ajaxJSON(url, callback) {
  var xhr = new XMLHttpRequest();
  xhr.onload = function() {
    try {
      callback(JSON.parse(xhr.responseText))
    } catch(e) {
      console.log('error')
    }
  };
  xhr.open('get', url);
  xhr.send();
}