// Tooltip

(function() {
  'use strict';

  var tooltip   = document.createElement('div');
  var mousemove = false;
  var timeout, duration;

  tooltip.classList.add('tooltip');
  tooltip.style.display = 'none';
  document.body.appendChild(tooltip);
  duration = parseFloat(window.getComputedStyle(tooltip).transitionDuration) * 1000;

  init.push(function() {
    var tooltips = document.querySelectorAll('[tooltip]');

    each(tooltips, function(tooltip, i) {
      initMouseEvents(tooltip);
    });
  });

  function initMouseEvents(tooltip) {
    tooltip.addEventListener('mouseenter', showTooltip, false);
    tooltip.addEventListener('mouseleave', hideTooltip, false);
  }

  function showTooltip(e) {
    tooltip.innerHTML     = this.getAttribute('tooltip');
    tooltip.style.display = 'block';
    tooltip.style.left    = getPage(e).pageX + 'px';
    tooltip.style.top     = getPage(e).pageY + 'px';
    tooltip.style.marginLeft = (tooltip.offsetWidth / -2) + 'px';

    setTimeout(function(){
      tooltip.classList.add('tooltip--show');
    });

    clearTimeout(timeout);
    if (!mousemove) {
      mousemove = true;
      document.addEventListener('mousemove', moveTooltip, false);
    }
  }

  function hideTooltip(e) {
    tooltip.classList.remove('tooltip--show');

    clearTimeout(timeout);
    timeout = setTimeout(function() {
      mousemove = false;
      tooltip.style.display = 'none';
      document.removeEventListener('mousemove', moveTooltip, false);
    }, duration, 10);
  }

  function moveTooltip(e) {
    tooltip.style.left = getPage(e).pageX + 'px';
    tooltip.style.top  = getPage(e).pageY + 'px';
  }

  function getPage(e) {
    return e.changedTouches ? e.changedTouches[0] : e;
  }
})();