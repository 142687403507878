// Animate

var css, animate, getStyle;

(function() {
  var getPrefix = function(property) {
    var vendors  = ['Webkit', 'Moz', 'O', 'ms'];
    var vendorProperty = property.charAt(0).toUpperCase() + property.substr(1);
    var div = document.createElement('div');
    if (div.style[property] !== undefined) return property;
    for (var i = 0; i < vendors.length; i++) {
      if (div.style[vendors[i]+vendorProperty] !== undefined) return vendors[i]+vendorProperty;
    }
    return false;
  };

  var prefix = {
    transform: getPrefix('transform'),
    transition: getPrefix('transition'),
    transitionDuration: getPrefix('transitionDuration'),
  };

  css = function(el, styles, cancel) {
    if (typeof el.animation === 'undefined') el.animation = {};
    cancel = (typeof cancel === 'undefined') ? true : cancel;
    var setStyles = {};
    if (cancel) styles.transition = '';
    for (var key in styles) {
      if (styles.hasOwnProperty(key)) {
        if (el.style[key] !== undefined) {
          el.style[key] = setStyles[key] = styles[key];
        } else {
          if (el.style[prefix[key]] !== undefined ) el.style[prefix[key]] = setStyles[key] = styles[key];
        }
      }
    }
    el.animation.styles = setStyles;
  };

  getStyle = function(el, style) {
    var styles = window.getComputedStyle(el);
    if (styles[style] !== undefined) {
      return styles[style];
    } else {
      var prefixed = getPrefix(style);
      if (styles[prefixed] !== undefined) return styles[prefixed];
    }
    return null;
  }

  function animateStop(el) {
    var setStyles = {};
    for (var key in el.animation.styles) {
      if (el.animation.styles.hasOwnProperty(key)) {
        setStyles[key] = getComputedStyle(el)[key]; // IE9+
      }
    }
    setStyles.transition = '';
    css(el, setStyles);
    return false;
  }

  animate = function(el, styles) {
    if (typeof el === 'undefined' || typeof styles === 'undefined') return false;
    if (styles == 'stop') { animateStop(el); return false; }
    if (typeof el.animation === 'undefined') el.animation = {};
    var duration = 500, easing = 'ease', callback = function() {};
    for (var i = 2, l = arguments.length; i < l; i++) {
      if (typeof arguments[i] === 'function') callback = arguments[i];
      if (typeof arguments[i] === 'string') easing = arguments[i];
      if (typeof arguments[i] === 'number') duration = arguments[i];
    }
    if (typeof el.animation.timeout !== 'undefined') clearTimeout(el.animation.timeout);
    if (typeof el.animation.styles !== 'undefined' && el.animation.styles) animateStop(el);

    if (isArray(styles)) {

      css(el, styles[0]);
      animate(el, styles[1], duration, easing, callback);

    } else {

      styles.transition = 'all '+ duration +'ms '+ easing;
      css(el, styles, false);

      el.animation.timeout = setTimeout(function() {
        css(el, { transition: '' });
        el.animation.styles = false;
        callback();
      }, duration);

    }
  };

})();