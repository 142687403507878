// Text Slider

var getStartedTextSlider;

init.push(function() {
  each(document.querySelectorAll('.text-slider'), function(slider, i) {
    var interval = parseInt(slider.getAttribute('text-slider-interval'));
    var id = slider.getAttribute('text-slider');
    var sliderActive = false;
    var sliderChanging = false;
    var timeout;

    var ease = 'cubic-bezier(0.455, 0.03, 0.515, 0.955)';
    var easeIn = 'cubic-bezier(0.55, 0.085, 0.68, 0.53)';
    var easeOut = 'cubic-bezier(0.25, 0.46, 0.45, 0.94)';
    var duration = 666;

    var item = slider.querySelectorAll('.text-slider__item');
    var count = item.length;
    var current = 0;

    var autoHeight = slider.hasAttribute('text-slider-auto-height');

    var dots = document.querySelector('.text-slider__dots[text-slider="'+ id +'"]');
    if (dots != null) {
      removeChild(dots);
      for (var i = 0; i < count; i++) {
        (function() {
          var dot = (dots.tagName.toLowerCase() == 'ul')
            ? document.createElement('li')
            : document.createElement('span');
          if (i == 0) dot.classList.add('is-active')
          dots.appendChild(dot);
        })();
      }
      dots = dots.querySelectorAll('*');
      each(dots, function(dot, i) {
        dot.onclick = function(e) {
          e.preventDefault();
          if (sliderChanging) return;
          sliderActive = false;
          changeSlide(i);
        }
      });
    } else {
      dots = false;
    }

    var arrows = document.querySelector('.text-slider__arrows[text-slider="'+ id +'"]');
    if (arrows != null) {
      if (count == 1) {
        arrows.style.visibility = 'hidden';
        arrows.style.height = 0;
      }
      arrows = {
        prev: arrows.querySelector('button[text-slider-prev]'),
        next: arrows.querySelector('button[text-slider-next]'),
        current: arrows.querySelector('.text-slider__arrows-status span:first-child'),
        max: arrows.querySelector('.text-slider__arrows-status span:last-child')
      }
      arrows.prev.classList.add('is-disabled');
      arrows.current.innerHTML = 1;
      arrows.max.innerHTML = count;
      arrows.prev.onclick = function(e) {
        e.preventDefault();
        if (sliderChanging) return;
        if (this.classList.contains('is-disabled')) return;
        if (current <= 0) return;
        sliderActive = false;
        changeSlide(current-1);
      };
      arrows.next.onclick = function(e) {
        e.preventDefault();
        if (sliderChanging) return;
        if (this.classList.contains('is-disabled')) return;
        if (current >= count - 1) return;
        sliderActive = false;
        changeSlide(current+1);
      };
    } else {
      arrows = false;
    }

    if (id == 'get-started') {
      getStartedTextSlider = function(active) {
        sliderActive = active;
        clearTimeout(timeout);
        if (active) {
          timeout = setTimeout(changeSlide, interval);
        }
      }
    }

    if (slider.hasAttribute('auto-start')) {
      timeout = setTimeout(changeSlide, interval);
    }

    function changeSlide(newIndex) {
      if (sliderChanging) return;
      sliderChanging = true;
      clearTimeout(timeout);

      var oldIndex = current;
      if (typeof newIndex !== 'undefined') {
        if (oldIndex == newIndex) {
          sliderChanging = false;
          return false;
        }
        current = newIndex;
      } else {
        current = (current++ < count - 1) ? current : 0;
      }

      var slideRight = current > oldIndex;
      var old = item[oldIndex];
      var next = item[current];

      if (dots !== false) {
        dots[current].classList.add('is-active');
        dots[oldIndex].classList.remove('is-active');
      }

      if (arrows !== false) {
        arrows.current.innerHTML = (current + 1);
        if (current == 0) {
          arrows.prev.classList.add('is-disabled');
        } else {
          arrows.prev.classList.remove('is-disabled');
        }
        if (current == count-1) {
          arrows.next.classList.add('is-disabled');
        } else {
          arrows.next.classList.remove('is-disabled');
        }
      }

      next.classList.add('is-active');

      css(next, { position: 'absolute', top: 0, left: 0, opacity: 0, transform: 'translateX('+ (slideRight ? '50px' : '-50px') +') translateZ(0)' });

      if (autoHeight) {
        animate(slider, [
          { height: old.offsetHeight + 'px', transform: 'translateZ(0)' },
          { height: next.offsetHeight + 'px', transform: 'translateZ(0)' }
        ], duration, ease);
      }

      animate(old, [
        { position: 'absolute', top: 0, left: 0, opacity: 1, transform: 'translateZ(0)' },
        { transform: 'translateX('+ (slideRight ? '-50px' : '50px') +') translateZ(0)', opacity: 0 }
      ], duration/2, easeIn, function() {
        animate(next, { transform: 'translateZ(0)', opacity: 1 }, duration/2, easeOut, function() {
          old.classList.remove('is-active');

          if (autoHeight) { css(slider, { transition: '', height: '' }); }
          css(old, { position: '', top: '', left: '', transform: '', opacity: '', transition: '' });
          css(next, { position: '', top: '', left: '', transform: '', opacity: '', transition: '' });

          sliderChanging = false;
          if (sliderActive) timeout = setTimeout(changeSlide, interval);
        });
      });
    }
  });
});