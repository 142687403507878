// Cover Video

init.push(function() {
  var coverVideos = document.querySelectorAll('[cover-video]');
  if (coverVideos.length == 0) return;

  videoAutoplaySupported(function(supported) {
    each(coverVideos, function(container, i) {
      var video  = container.querySelector('video');
      var width  = parseFloat(video.getAttribute('width'));
      var height = parseFloat(video.getAttribute('height'));
      var ratio = width / height;

      windowResize('coverVideo' + i, function(e) {
        var w = container.offsetWidth;
        var h = container.offsetHeight;
        if (w / h > ratio) {
          h = w / ratio;
        } else {
          w = h * ratio;
        }
        video.style.width = w + 'px';
        video.style.height = h + 'px';
      }, true);

      video.style.visibility = 'visible';
    });
  });
});