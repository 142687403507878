var initLoadMore;

;(function() {

  initLoadMore = function() {
    var loadMore = document.querySelector('.load-more');
    if (loadMore == null) return;

    var wrapper = loadMore.querySelector('.load-more__wrapper');
    var holder = loadMore.querySelector('.load-more__holder');
    var trigger = loadMore.querySelector('.load-more__trigger');
    if (holder == null || trigger == null) return;

    var firstItem = holder.querySelector('.load-more__item');
    if (firstItem == null) return;

    var loading = false;
    var page = 0;
    var itemsPerPage = parseInt(loadMore.getAttribute('items-per-page'));

    windowScroll('loadMoreTrigger', function() {
      if (loading) return;
      var rect = trigger.getBoundingClientRect();
      if (rect.top < window.innerHeight && rect.top > 0) {
        loadItems();
      }
    }, false);

    function loadItems() {
      if (loading) return;
      loading = true;

      trigger.classList.add('is-active');
      page++;

      var action = window.location.origin + window.location.pathname;
      action += ((window.location.search == '') ? '?' : window.location.search + '&') + 'page=' + page;

      ajax({
        action: action,
        csrf: true,
        success: function(data) {
          var div = document.createElement('div');
          div.style.cssText = 'position: absolute; top: 0; left: 0; height: 0; width: 100%; overflow: hidden; opacity: 0;'
          div.innerHTML = data;
          document.body.appendChild(div);

          var wrapperTemp = div.querySelector('.load-more__holder');
          var items = wrapperTemp.querySelectorAll('.load-more__item');

          var wrapperHeight = wrapper.offsetHeight;
          var wrapperTempHeight = wrapperTemp.offsetHeight;
          var noMoreMarginTop = 0;

          css(wrapper, { height: wrapperHeight + 'px' });
          trigger.classList.remove('is-active');

          if (items.length > 0) {
            each(items, function(item) {
              css(item, {opacity: 0, transform: 'scale(0.9)'});
              wrapper.appendChild(item);
            });
          } else {
            var noMore = wrapperTemp.querySelector('.blog__list-empty');
            wrapper.appendChild(noMore);
            noMoreMarginTop = parseInt(window.getComputedStyle(noMore).marginTop);
          }

          var itemMarginBottom = parseInt(window.getComputedStyle(firstItem).marginBottom);

          animate(wrapper, { height: (
            wrapperHeight + wrapperTempHeight + itemMarginBottom + noMoreMarginTop) + 'px'
          }, duration, easing);

          if (items.length > 0) {
            each(items, function (item, i) {
              setTimeout(function () {
                animate(item, {opacity: 1, transform: 'none'}, duration, easing);
              }, (i+1) * 50);
            });
          }

          setTimeout(function() {
            if (items.length > 0) loading = false;
            css(wrapper, { height: '' });
          }, duration + items.length * 50);

          document.body.removeChild(div);
          div = null;
        }
      });
    }
  }

  init.push(function() {
    initLoadMore();
  });

})();