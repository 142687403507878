// Passive Event
var passiveEvent = (function(passiveSupported) {
  try {
    var options = Object.defineProperty({}, "passive", {
      get: function() { passiveSupported = true; }
    });

    window.addEventListener("test", options, options);
    window.removeEventListener("test", options, options);
  } catch(err) {
    passiveSupported = false;
  }

  return passiveSupported ? { passive: true } : false;
})();