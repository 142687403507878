// // Wipe Slider

init.push(function() {
  each(document.querySelectorAll('.header__slider'), function(slider, i) {
    var wrapper   = slider.querySelector('.header__slider-wrapper');
    var article   = slider.querySelector('.header__slider-article');
    var word      = slider.querySelector('.header__slider-word');
    if (wrapper == null || article == null || word == null) return;

    var slides = slider.getAttribute('slider-words').split(',');
    var count = slides.length;
    var currentIndex = 0;
    var interval = parseInt(slider.getAttribute('slider-interval'));
    var typeDuration = parseInt(slider.getAttribute('slider-type-speed'));

    setTimeout(changeWord, interval);

    function deleteWord(container, word, callback) {
      var typeInterval = setInterval(function() {
        word = word.substring(0, word.length - 1);
        container.innerHTML = word;
        if (word.length == 0) {
          clearInterval(typeInterval);
          callback();
        }
      }, typeDuration);
    }

    function typeWord(container, word, callback) {
      var wordLength = word.length;
      var currentChar = 0;
      var currentWord = '';
      var typeInterval = setInterval(function() {
        currentWord += word[currentChar];
        container.innerHTML = currentWord;
        currentChar++;
        if (currentChar == wordLength) {
          clearInterval(typeInterval);
          callback();
        }
      }, typeDuration);
    }

    function changeWord() {
      deleteWord(word, word.innerHTML, function() {
        deleteWord(article, article.innerHTML, function() {
          setTimeout(function() {
            currentIndex = (currentIndex++ < count - 1) ? currentIndex : 0;
            var newArticle = /[aeiou]/.test(slides[currentIndex].charAt(0).toLowerCase()) ? 'an ' : 'a ';
            var newWord = slides[currentIndex] +',';
            typeWord(article, newArticle, function() {
              typeWord(word, newWord, function() {
                setTimeout(changeWord, interval);
              });
            });
          }, typeDuration);
        });
      });
    }

  });
});