// Team Filter

var teamFilterSortOrder;

init.push(function() {
  var team = document.querySelector('.team');
  var ie = document.body.classList.contains('ie');
  if (team == null) return;

  var canSort = true;  
  
  var holder = team.querySelector('.team__members');
  var grid = holder.querySelector('.grid');
  var items = [];
  var mapRegion = {};
  var filters = {};
  var itemsPerRegion = {};
  var currentRegion = holder.getAttribute('default-region');
  var hoverRegion;
  var sortBy = team.querySelector('.team__sort .dropdown__input').value;

  var scrollTo = team.querySelector('.team__scroll-to');

  var mapLoadInterval = setInterval(function() {
    if (document.querySelector('.team__map .team__map-region') != null) {
      initTeamFilter();
      clearInterval(mapLoadInterval);
    }
  }, 100);

  function initTeamFilter() {
    each(team.querySelectorAll('.team__filter a'), function(filter, i) {
      var filterRegion = filter.getAttribute('region');
      filters[filterRegion] = filter;
      if (filter.classList.contains('is-active')) currentRegion = filterRegion;

      filter.onmouseenter = function() {
        hoverRegion = this.getAttribute('region');
        if (hoverRegion != 'all') {
          if (!ie) mapRegion[hoverRegion].classList.add('is-hover');
        } else {
          if (!team.classList.contains('is-all')) {
            eachObject(mapRegion, function(region, el) {
              if (!ie) el.classList.add('is-hover');
            });
          }
        }
      }

      filter.onmouseleave = function() {
        if (hoverRegion != 'all') {
          if (!ie) mapRegion[hoverRegion].classList.remove('is-hover');
        } else {
          eachObject(mapRegion, function(region, el) {
            if (!ie) el.classList.remove('is-hover');
          });
        }
      }

      filter.onclick = function(e) {
        e.preventDefault();
        if (!this.classList.contains('is-active') && !this.classList.contains('is-disabled')) {
          var newRegion = this.getAttribute('region');
          var activeItem = getActiveItem(newRegion);
          if (activeItem !== false) {
            filters[currentRegion].classList.remove('is-active');
            filters[newRegion].classList.add('is-active');
            if (currentRegion != 'all') {
              if (!ie) mapRegion[currentRegion].classList.remove('is-active');
            } else {
              eachObject(mapRegion, function(region, el) {
                if (!ie) el.classList.remove('is-active');
              });
            }
            if (newRegion != 'all') {
              if (!ie) mapRegion[newRegion].classList.add('is-active');
              team.classList.remove('is-all');
            } else {
              eachObject(mapRegion, function(region, el) {
                if (!ie) el.classList.add('is-active');
                if (!ie) el.classList.remove('is-hover');
              });
              team.classList.add('is-all');
            }
            currentRegion = newRegion;
            sortItems(currentRegion, activeItem);
          }
        }
      }
    });

    each(team.querySelectorAll('.team__map .team__map-region'), function(region, i) {
      var regionRegion = region.getAttribute('region')
      mapRegion[regionRegion] = region;
      if (regionRegion == currentRegion && !ie) region.classList.add('is-active');
      itemsPerRegion[regionRegion] = 0;

      region.onmouseenter = function() {
        hoverRegion = this.getAttribute('region');
        filters[hoverRegion].classList.add('is-hover');
      }

      region.onmouseleave = function() {
        filters[hoverRegion].classList.remove('is-hover');
      }

      region.onclick = function(e) {
        e.preventDefault();
        filters[this.getAttribute('region')].click();
      }
    });

    each(document.querySelectorAll('.team__members-item'), function(item, i) {
      var itemRegion = item.getAttribute('region');
      if (itemRegion != currentRegion) item.style.display = 'none';
      items[i] = {
        el: item,
        id: i,
        region: itemRegion,
        visible: (itemRegion == currentRegion),
        grid: { x: 0, y: 0 },
        name: item.querySelector('.team__members-item-name').innerHTML.trim(),
        position: item.querySelector('.team__members-item-position').innerHTML.trim(),
        location: item.querySelector('.team__members-item-location').innerHTML.trim(),
      }
      itemsPerRegion[itemRegion]++;
    });

    eachObject(itemsPerRegion, function(region, items) {
      if (items == 0) {
        if (!ie) mapRegion[region].classList.add('is-disabled');
        filters[region].classList.add('is-disabled');
      }
    });

    teamFilterSortOrder = function(sortOrder) {
      sortBy = sortOrder;
      var activeItem = getActiveItem(currentRegion);
      if (activeItem !== false) {
        sortItems(currentRegion, activeItem);
      }
    }

    reorderItems();
    reorderItemsDOM();
  }

  function reorderItems() {
    items.sort(function(a, b){
     var nameA = a[sortBy].toLowerCase(), nameB = b[sortBy].toLowerCase();
     return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
    });
  }

  function reorderItemsDOM() {
    each(items, function(item) {
      grid.appendChild(item.el);
    });
  }

  function updatePosition(grid, xmax) {
    grid.x++; if (grid.x >= xmax) { grid.x = 0; grid.y++; }
  }

  function getActiveItem(region) {
    if (!canSort) return false;

    var activeIndex = -1;
    var itemsForShow = 0;

    each(items, function(item, i) {
      if (activeIndex < 0 && item.visible == true) {
        activeIndex = i;
      }
      if (region == 'all' || item.region == region) {
        itemsForShow++;
      }
    });

    if (activeIndex < 0 || itemsForShow == 0) return false;

    return activeIndex;
  }

  function sortItems(region, activeItem) {
    if (!canSort) return false;
    canSort = false;

    var holderWidth = holder.offsetWidth;
    var holderHeight = holder.offsetHeight;
    var itemWidth = items[activeItem].el.offsetWidth;
    var itemHeight = items[activeItem].el.offsetHeight;
    var itemsPerRow = Math.round(holderWidth / itemWidth);
    var activeItems = 0;

    each(items, function(item, i) {
      if (region == 'all' || item.region == region) {
        activeItems++;
        item.visible = true;
      } else {
        item.visible = false;
      }
    });

    animate(holder, [{
        opacity: 1,
        width: holderWidth + 'px',
        height: holderHeight + 'px',
        transform: 'translate3d(0,0,0)',
      }, {
        opacity: 0,
        width: holderWidth + 'px',
        height: (Math.ceil(activeItems / itemsPerRow) * itemHeight) + 'px',
        transform: 'translate3d(0,0,0)',
    }], duration / 2, easing);

    if (scrollTo != null) {
      scroll.animateScroll(scrollTo, scrollTo, smoothScrollToOptions);
    }

    setTimeout(function() {

      each(items, function(item, i) {
        if (item.visible) {
          css(item.el, {
            display: '',
            transform: 'translate3d(0, 0, 0) scale(0.9)',
            opacity: 0,
          });
        } else {
          css(item.el, {
            display: 'none',
            transform: '',
            opacity: '',
          });
        }
      });

      css(holder, { opacity: '', width: '', height: '', transform: '' });

      reorderItems();
      reorderItemsDOM();

      var activeItems = 0;

      each(items, function(item, i) {
        if (item.visible) {
          setTimeout(function() {
            animate(item.el, {
              transform: 'translate3d(0, 0, 0) scale(1)',
              opacity: 1
            }, duration, easing);
          }, Math.min(activeItems * 50, 8 * 50));
          activeItems++;
        }
      });

      setTimeout(function() {
        css(holder, { height: '', width: '' });
        each(items, function(item, i) {
          css(item.el, {
            transform: '',
            opacity: '',
          });
        });
        canSort = true;
      }, duration + Math.min(activeItems * 50, 8 * 50));

    }, duration/2);

    return true;
  }
});
