document.addEventListener('DOMContentLoaded', function() {
    initCookieConsent();

    function initCookieConsent() {
        window.cookieconsent.initialise({
            palette: {
                "popup": {
                    "background": "#1c1f27",
                    "text": "#ffffff"
                },
            },
            theme: "classic",
            position: "bottom-right",
            type: "opt-out",
            content: {
                dismiss: "Allow cookies",
                deny: "Decline"
            },
            elements: {
                header: '<span class="cc-header">hej ti</span>',
                message: '<span id="cookieconsent:desc" class="cc-message">This website uses cookies to ensure you get the best experience on our website.</span>',
                allow: '<a aria-label="allow cookies" tabindex="0" class="cc-btn cc-allow button button--style-default button--size-s button--color-gradient"">Allow cookies</a>',
                deny: '<a aria-label="deny cookies" tabindex="0" class="cc-btn cc-deny button button--style-outline button--size-s button--color-gradient">Decline</a>',
                link: '<a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="https://2amigos.us" target="_blank">Learn more.</a>',
            },
            onInitialise: function(status) {
                if (status === 'allow') {
                    initTrackingScripts();
                }
            },
            onStatusChange: function(status, chosenBefore) {
                if (status === 'allow') {
                    initTrackingScripts();
                }
            },
        });
    }

    function initTrackingScripts() {
        initGoogleTracking();
        initLinkedInTracking();
    }

    function initGoogleTracking() {
        console.log('init google tracking');
        var s = document.getElementsByTagName("script")[0];
        var b = document.createElement("script");
        b.type = "text/javascript";
        b.async = true;
        b.src = "https://www.googletagmanager.com/gtag/js?id=UA-58283415-2";
        s.parentNode.insertBefore(b, s);

        window.dataLayer = window.dataLayer || [];

        function gtag() {
            dataLayer.push(arguments);
        }

        gtag('js', new Date());
        gtag('config', 'UA-58283415-2');
    }

    function initLinkedInTracking() {
        console.log('init linkedin tracking');
        _linkedin_partner_id = "500474";
        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
        window._linkedin_data_partner_ids.push(_linkedin_partner_id);

        var s = document.getElementsByTagName("script")[0];
        var b = document.createElement("script");
        b.type = "text/javascript";
        b.async = true;
        b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
        s.parentNode.insertBefore(b, s);
    }
});
