// Content Switch

var contentSwitch;

init.push(function() {

  var contentSwitches = {};
  var switching = false;

  var ease = 'cubic-bezier(0.455, 0.03, 0.515, 0.955)';
  var easeIn = 'cubic-bezier(0.55, 0.085, 0.68, 0.53)';
  var easeOut = 'cubic-bezier(0.25, 0.46, 0.45, 0.94)';
  var duration = 700;

  each(document.querySelectorAll('.content-switch'), function(switcher, i) {
    var pages = {};
    var active;

    each(switcher.querySelectorAll('.content-switch__page'), function(page) {
      var id = page.getAttribute('content-switch-page');
      pages[id] = page;
      if (page.classList.contains('is-active')) active = id;
    });

    contentSwitches[switcher.getAttribute('content-switch')] = {
      el: switcher,
      pages: pages,
      active: active,
    };
  });

  contentSwitch = function(contentSwitch, contentSwitchPage, callback) {
    if (switching) return;

    var switcher = contentSwitches[contentSwitch].el;
    var pages = contentSwitches[contentSwitch].pages;
    var old = pages[contentSwitches[contentSwitch].active];
    var next = pages[contentSwitchPage];

    if (typeof old === 'undefined' || typeof next === 'undefined') return;

    if (old == next) return;
    switching = true;

    switcher.style.height = old.offsetHeight + 'px';

    css(old, { position: 'absolute', left: 0, top: 0, width: '100%', opacity: 1 });
    css(next, { position: 'absolute', left: 0, top: 0, width: '100%', opacity: 0, display: 'block' });

    animate(switcher, { height: next.offsetHeight + 'px', transform: 'translateZ(0)' }, duration, ease);
    animate(old, { opacity: 0, transform: 'translateZ(0)' }, duration/2, easeIn, function() {
      animate(next, { opacity: 1, transform: 'translateZ(0)' }, duration/2, easeOut, function() {
        css(switcher, { transition: '', height: '', width: '', transform: '' });
        css(next, { transition: '', opacity: '', position: '', top: '', left: '', width: '', display: '', transform: '' });
        css(old, { transition: '', opacity: '', position: '', top: '', left: '', width: '', transform: '' });

        next.classList.add('is-active');
        old.classList.remove('is-active');

        contentSwitches[contentSwitch].active = next.getAttribute('content-switch-page');

        if (typeof callback === 'undefined') callback = function() {};
        callback();

        switching = false;
      });
    });
  }
});