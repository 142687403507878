// Bar Scroll

init.push(function() {
  var bar = {
    main: {
      el: document.querySelector('.bar.bar--default'),
      active: false,
      boundary: 100,
    },
    category: {
      el: document.querySelector('.bar.bar--style-category'),
      active: false,
      boundary: 0,
    },
  };

  if (bar.category.el == null) {
    bar.category = false;
  } else {
    bar.holders = {
      extra: bar.main.el.querySelector('.bar__extra'),
      category: document.querySelector('.bar__category-wrapper'),
    };
    windowResize('barScroll', function(e) {
      var desktop = window.outerWidth >= 1024;
      var menuHeight = (desktop) ? 80 : 70;
      var boundary = bar.holders.category.offsetTop - menuHeight;

      bar.category.boundary = {
        max: boundary,
        min: boundary + 12,
      }
    }, true);
  }

  var oldScrollTop = document.scrollingElement.scrollTop;

  windowScroll('barScroll', function(e, scrollTop) {
    var scrollDown = (scrollTop > oldScrollTop);
    oldScrollTop = scrollTop;

    if (scrollTop > bar.main.boundary && !bar.main.active) {
      bar.main.active = true;
      bar.main.el.classList.add('is-active');
    } else if (scrollTop < bar.main.boundary && bar.main.active) {
      bar.main.active = false;
      bar.main.el.classList.remove('is-active');
    }
    if (bar.category !== false) {
      if (scrollTop > bar.category.boundary.max && !bar.category.active && scrollDown) {
        bar.category.active = true;
        bar.holders.extra.appendChild(bar.category.el);
        bar.main.el.classList.add('is-category');
      } else if (scrollTop < bar.category.boundary.min && bar.category.active && !scrollDown) {
        bar.category.active = false;
        bar.holders.category.appendChild(bar.category.el);
        bar.main.el.classList.remove('is-category');
      }
    }
  }, true);
});